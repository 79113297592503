const getUrlOrigin = (data: string) => {
  const a = document.createElement('a')
  a.href = data
  return a.origin
}

export const getCORSsafeOrigin = () => {
  const host = location.host.replace('www.', '').replace('test.', '')
  const originWhiteList = [
    'http://localhost:3000',
    'http://localhost:8080',
    `https://admin.${host}`
  ]
  const origin = getUrlOrigin(document.referrer)
  return originWhiteList.includes(origin) ? origin : null
}
