<template>
  <main
    ref="pageWrapper"
    class="page-wrapper page-wrapper--no-height"
    :style="`--page-height: ${height}px;`"
  >
    <ClientOnly>
      <PixelsBackground v-if="!page?.pixels_disabled" />
    </ClientOnly>
    <NavigationHeader class="nav-header non-clickable" />
    <ClientOnly>
      <Breadcrumbs />
    </ClientOnly>
    <slot />
    <div ref="root" class="preview-wrapper" :class="{ locked: dataSending }">
      <WidgetsAdminPreview
        v-if="preview && page?.widgets"
        :widgets="page.widgets"
        :new-widget-y-pos="newWidgetYpos"
        :scale="scale"
        :locale="locale"
        :virtual-first-widget="virtualFirstWidget"
        @add-widget="addWidget"
        @new-widget="newWidget"
        @edit-widget="editWidget"
        @remove-widget="removeWidget"
        @move-up-widget="moveUpWidget"
        @move-down-widget="moveDownWidget"
      />
    </div>
    <NavigationFooter class="nav-footer non-clickable" />
  </main>
</template>

<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'

import { useIsMobile } from '@/composables/useIsMobile'
import { usePreviewModule } from '@/composables/usePreviewModule'

const virtualFirstWidget = ref(false)

const {
  root,
  newWidgetYpos,
  preview,
  page,
  dataSending,
  scale,
  addWidget,
  newWidget,
  removeWidget,
  moveUpWidget,
  moveDownWidget,
  editWidget,
  editHeader,
  locale
} = usePreviewModule()
provide('page', page)
provide('editHeader', editHeader)
provide('virtualFirstWidget', virtualFirstWidget)

useIsMobile()

const pageWrapper = ref<HTMLElement>()
const { height } = useElementSize(pageWrapper)
provide('pageHeight', height)
</script>

<style lang="scss" scoped>
.non-clickable:deep(*) {
  pointer-events: none !important;
  user-select: none !important;
}

.nav-header {
  order: 1;
}

.preview-wrapper {
  z-index: 1;
  order: 3;
}

.nav-footer {
  order: 5;
}
</style>
