<template>
  <div
    v-if="type === 'place'"
    class="widget-placeholder"
    v-text="'Place for new widget'"
  />
  <button v-else class="widget-placeholder add" @click="$emit('newWidget')">
    <svg
      class="icon"
      width="200"
      height="200"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        d="M480 480V128a32 32 0 0164 0v352h352a32 32 0 110 64H544v352a32 32 0 11-64 0V544H128a32 32 0 010-64h352z"
      />
    </svg>
    <span>{{ t('add-new-widget', 1, { locale }) }}</span>
  </button>
</template>

<i18n lang="json">
{
  "pl": {
    "add-new-widget": "Dodaj nowy widget"
  },
  "en": {
    "add-new-widget": "Add new widget"
  }
}
</i18n>

<script setup lang="ts">
withDefaults(defineProps<{ type?: 'place' | 'add'; locale?: string }>(), {
  type: 'place',
  locale: 'en'
})

defineEmits(['newWidget'])

const { t } = useI18n({ useScope: 'local' })
</script>

<style lang="scss" scoped>
.widget-placeholder {
  width: calc(100% - 40px);
  height: rem(60px);
  max-height: 0;
  margin: 0 rem(20px);
  font-family:
    Inter,
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: rem(20px);
  line-height: rem(60px);
  color: $preview-blue;
  text-align: center;
  user-select: none;
  border: rem(1px) solid $preview-blue;
  border-radius: rem(20px);
  opacity: 0;
  transition:
    opacity 0.2s,
    max-height 0.2s,
    background-color 0.2s;

  &.add {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: rem(10px) rem(20px);
    color: #ffffff;
    background-color: $success;
    border: rem(1px) solid $success;

    &:hover {
      background-color: $success-light-3;
    }

    .icon {
      @include size(rem(30px));
      margin-right: rem(10px);

      path {
        fill: #ffffff;
      }
    }
  }

  &.active {
    max-height: rem(60px);
    opacity: 1;
  }
}
</style>
