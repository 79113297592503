<template>
  <div
    v-for="(widget, index) in widgets"
    :key="widget.id"
    :ref="setWidgetsRef"
    class="widget-wrapper widget-margins"
    :class="[
      { 'in-edition': index === widgetInEditionIndex },
      `${useKebabcase(widget.resourcetype)}-wrapper`,
      `widget-mt-${widget.margin_top ?? 'none'}`,
      `widget-mb-${widget.margin_bottom ?? 'none'}`
    ]"
    role="button"
    tabindex="0"
    @click="$emit('editWidget', index)"
    @keydown.enter.space.prevent="$emit('editWidget', index)"
  >
    <PlaceholderWidget :class="{ active: activeIndex === index }" />
    <Component
      :is="getWidget(widget)"
      :id="`widget-${widget.id}`"
      :widget="widget"
      :is-first="index === 0 && !virtualFirstWidget"
      :lazy-image="false"
      class="widget"
    />
    <div class="actions-buttons" :style="`transform: scale(${1 / scale})`">
      <div class="button-group">
        <button
          v-if="isMoreThanOneWidget && isNotFirstWidget(index)"
          class="move-up"
          @click.stop="$emit('moveUpWidget', index)"
        >
          <svg
            class="icon"
            width="200"
            height="200"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(-90deg)"
          >
            <path
              fill="currentColor"
              d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"
            />
          </svg>
        </button>
        <button
          v-if="isMoreThanOneWidget && isNotLastWidget(index)"
          class="move-down"
          @click.stop="$emit('moveDownWidget', index)"
        >
          <svg
            class="icon"
            width="200"
            height="200"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: rotate(90deg)"
          >
            <path
              fill="currentColor"
              d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"
            />
          </svg>
        </button>
      </div>
      <button class="remove" @click.stop="$emit('removeWidget', index)">
        <svg
          class="icon"
          width="200"
          height="200"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M160 256H96a32 32 0 010-64h256V95.936a32 32 0 0132-32h256a32 32 0 0132 32V192h256a32 32 0 110 64h-64v672a32 32 0 01-32 32H192a32 32 0 01-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 01-32-32V416a32 32 0 0164 0v320a32 32 0 01-32 32zm192 0a32 32 0 01-32-32V416a32 32 0 0164 0v320a32 32 0 01-32 32z"
          />
        </svg>
      </button>
    </div>
    <div class="hover-bg" />
  </div>
  <PlaceholderWidget
    :locale="locale"
    :type="!widgets.length ? 'add' : 'place'"
    :class="{ active: activeIndex === widgets.length || !widgets.length }"
    @new-widget="$emit('newWidget', 0)"
  />
</template>

<script lang="ts" setup>
import { PublicWidget } from '@/service/__generated-api'
import { useKebabcase } from '~/composables/useKebabcase'

const props = withDefaults(
  defineProps<{
    widgets: PublicWidget[]
    newWidgetYPos?: number
    scale?: number
    locale: string
    virtualFirstWidget?: boolean
  }>(),
  { newWidgetYPos: -1, scale: 1 }
)

const getWidget = (widget: PublicWidget) =>
  resolveComponent(`Widgets${widget.resourcetype}`)

const emit = defineEmits([
  'addWidget',
  'newWidget',
  'removeWidget',
  'moveUpWidget',
  'moveDownWidget',
  'editWidget'
])

const activeIndex = ref(-1)
const widgetInEditionIndex = ref(-1)
const isMoreThanOneWidget = computed(() => props.widgets.length > 1)

let widgetsRef: HTMLElement[] = []

const setWidgetsRef = (widget: HTMLElement) => {
  if (widget) {
    widgetsRef.push(widget)
  }
}

const isNotFirstWidget = (index: number) => index

const isNotLastWidget = (index: number) => index !== props.widgets.length - 1

watch(
  () => props.newWidgetYPos,
  value => {
    activatePlaceholder(value)
  }
)
watch(activeIndex, value => emit('addWidget', value))

const deactivatePlaceholder = () => {
  activeIndex.value = -1
}

const activatePlaceholder = (newYPos: number) => {
  if (!props.widgets.length) {
    activeIndex.value = 0
  }
  widgetsRef.every((widget, index) => {
    const { top, height } = widget.getBoundingClientRect()
    if (!newYPos || newYPos < top + height / 2) {
      activeIndex.value = index
      return false
    } else if (props.widgets.length - 1 === index) {
      activeIndex.value = index + 1
      return false
    } else {
      return true
    }
  })
}

watch(() => props.widgets, deactivatePlaceholder)

onBeforeUpdate(() => {
  widgetsRef = []
})
</script>

<style lang="scss" scoped>
.widget-wrapper {
  position: relative;

  &:hover .hover-bg,
  &:hover .actions-buttons,
  &.in-edition .hover-bg,
  &.in-edition .actions-buttons {
    opacity: 1;
  }
}

.hover-bg {
  @include fill-space;
  top: calc(-1 * #{$widget-margin-top});
  z-index: 9999;
  height: calc(100% + #{$widget-margin-top} + #{$widget-margin-bottom});
  cursor: pointer;
  user-select: none;

  border: rem(2px) solid $preview-blue;

  opacity: 0;
  transition: opacity 0.3s;
}

.widget {
  min-height: rem(50px);
  font-size: rem(20px);
  cursor: pointer;

  :deep(*) {
    pointer-events: none;
    user-select: none;
  }
}

.actions-buttons {
  position: absolute;
  top: calc(-1 * #{$widget-margin-top});
  right: 0;
  z-index: 99999;
  display: flex;
  gap: rem(6px);
  justify-content: flex-end;
  width: 100%;
  padding: rem(3px);
  opacity: 0;
  transition: opacity 0.2s;
  transform-origin: top right;

  .button-group {
    display: flex;
    flex-direction: row;

    & button:first-child:not(:only-child) {
      border-radius: rem(5px) 0 0 rem(5px);
    }

    & button:last-child:not(:only-child) {
      border-radius: 0 rem(5px) rem(5px) 0;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(31.5px);
    height: rem(27px);
    font-size: rem(60px);

    color: #ffffff;
    cursor: pointer;
    background-color: $preview-blue;
    border: rem(1px) solid $preview-blue;
    border-radius: rem(5px);
    transition:
      background-color 0.2s,
      color 0.2s;

    .icon path {
      fill: currentColor;
    }

    &:hover {
      background-color: rgba($preview-blue, 0.9);
    }
  }
}

.icon {
  @include size(rem(15px));
  box-sizing: border-box;
  font-size: inherit;
  line-height: 1em;
  vertical-align: middle;
}
</style>
